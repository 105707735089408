import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=c18e558c"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"
import style0 from "./Card.vue?vue&type=style&index=0&id=c18e558c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports